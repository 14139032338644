import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const EmailTemplates = () => {
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    fetch(`https://server.enjoywiki.com/hunt-crow/generate-email-templates`)
      .then((res) => res.json())
      .then((info) => setTemplates(info))
      .catch((error) => console.error("Error fetching templates:", error));
  }, []);

  // Function to create a data URL for iframe content
  const createIframeSrc = (htmlContent) => {
    return `data:text/html;charset=utf-8,${encodeURIComponent(htmlContent)}`;
  };

  return (
    <>
      <div className="pricing-2_main_pricing-section">
        <div className="container">
          <div className="row text-center justify-content-center">
            <div className="col-xxl-6 col-lg-7 col-md-9">
              <div className="section-heading">
                <h2 className="section-heading__title heading-md">
                  Email Templates
                </h2>
                <p className="mb-5">Choose the one that fits your brand best.</p>
              </div>
            </div>
          </div>
          <div
            className="row row--custom gutter-y-30"
            data-plan-id="pricing-1"
          >
            {templates.map((e, index) => (
              <div
                className="col-lg-4 col-md-6"
                key={index}
              >
                <div className="pricing-card active">
                  <div className="pricing-card__head">
                    {/* Display email template in an iframe */}
                    <iframe
                      src={createIframeSrc(e.htmlCode)}
                      style={{ width: '100%', height: '200px', border: 'none', borderRadius: '4px' }}
                      title={`Preview of template ${e._id}`}
                    />
                  </div>
                  <div className="pricing-card__body">
                    <div className="pricing-card__button">
                      <Link to={`/template/${e._id}`} className="btn-masco rounded-pill w-100">
                        View
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailTemplates;
