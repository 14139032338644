import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const UpdateProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});

  useEffect(() => {
    fetch(`https://server.enjoywiki.com/hunt-crow/profile/${id}`)
      .then((res) => res.json())
      .then((info) => {
        setProfile(info);
      });
  }, [id]);

  const handleUpdateProfile = async (event) => {
    event.preventDefault();
    const userName = event.target.userName.value;

    try {
      const userUpdate = {
        userName,
        profileImg: profile.profileImg, // Keep existing image (no new image handling)
      };

      const url = `https://server.enjoywiki.com/hunt-crow/update-profile/${id}`;
      fetch(url, {
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify(userUpdate),
      })
        .then((res) => res.json())
        .then(() => {
          window.location.href = "/user-dashboard"; // Redirect after update
        });
    } catch (error) {
      console.error("Profile update failed:", error);
    }
  };

  return (
    <div className="vh-100">
      <form className="form" onSubmit={handleUpdateProfile}>
        <div className="account-section bg-light-2 section-padding-120">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xxl-6 col-xl-7 col-lg-8 col-md-10">
                <div className="account-heading-block">
                  <div className="account-heading">
                    <h2 className="account-heading__title heading-md">
                      Update Profile
                    </h2>
                  </div>
                </div>
                <div className="account_comment-box">
                  <div className="account_comment-box__form-inner">
                    <div className="account_comment-box__form-input">
                      <h2 className="account-title">Your name</h2>
                      <input
                        type="text"
                        name="userName"
                        defaultValue={profile.userName}
                        placeholder="Enter your full name"
                        className="form-control bg-white"
                        required
                      />
                    </div>
                    <div className="account_comment-box__form-input-button">
                      <button type="submit" className="btn-masco rounded-pill w-100">
                        Update Profile
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateProfile;
