// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCkyWExo9ymgP10H6_G_sAPoTUWmUUNBi4",
  authDomain: "huntcrow-8d663.firebaseapp.com",
  projectId: "huntcrow-8d663",
  storageBucket: "huntcrow-8d663.appspot.com",
  messagingSenderId: "107988099615",
  appId: "1:107988099615:web:00e1b641c26121f407ab2a"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const auth = getAuth(app);

export default auth;