
import React, { useState, useRef, useEffect } from "react";


const CodeEditor = () => {
    const [htmlCode, setHtmlCode] = useState(`
        <style>
            body { font-family: Arial, sans-serif; }
            p { color: #333; }
        </style>
        <p>Edit me directly or in the editor!</p>
    `);
    const iframeRef = useRef(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            const document = iframe.contentDocument;
            if (document) {
                document.open();
                document.write(htmlCode);
                document.close();
            }
        }
    }, [htmlCode]);

    const handleCodeChange = (event) => {
        setHtmlCode(event.target.value);
    };

    const handleIframeInput = () => {
        const iframe = iframeRef.current;
        if (iframe) {
            const document = iframe.contentDocument;
            if (document) {
                setHtmlCode(document.documentElement.outerHTML);
            }
        }
    };

    return (
        <div className="contact-2_form-section padding-bottom-100 container">
            
          
            <div className="form-box-style__form-input-button d-flex justify-content-center">
                <button type="submit" className="btn-masco rounded-pill" >
                   Generate Email Template
                </button>
            </div>

            <div style={{ marginBottom: "20px" }}>
                <h2>HTML Code Editor</h2>
                <textarea
                    value={htmlCode}
                    onChange={handleCodeChange}
                    rows="10"
                    style={{ width: "100%", padding: "10px", fontFamily: "monospace", borderRadius: "4px", border: "1px solid #ccc" }}
                />
            </div>
            <div>
                <h2>Live Preview (Editable)</h2>
                <iframe
                    ref={iframeRef}
                    style={{ width: "100%", height: "400px", border: "none", borderRadius: "4px" }}
                    title="Live Preview"
                    onLoad={() => {
                        const iframe = iframeRef.current;
                        if (iframe) {
                            const document = iframe.contentDocument;
                            if (document) {
                                document.body.contentEditable = true;
                                document.body.addEventListener('input', handleIframeInput);
                            }
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default CodeEditor;
